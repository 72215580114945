<template>
  <section class="container">

    <div v-if="!isConfirm"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}"
         class="change-email">

      <h3 class="title">メールアドレス変更</h3>

      <div class="change-email__container step">

        <h4>ご希望のメールアドレスをご入力ください。<br>新しいメールアドレスにメールアドレス変更用URLを送付させていただきます。</h4>

        <div class="step__register">

          <ValidationObserver
            slim
            tag="form"
            ref="observer"
            @submit.prevent="validateBeforeSubmit()">
            <!--Error server-->
            <ul v-if="errors.length"
                class="error__server">
              <li v-for="(err, index) in errors"
                  :key="index"
                  v-text="err"/>
            </ul>

            <div class="step__form ">

              <div class="step__form__group">

                <!--mail-->
                <div class="group__item">
                  <!--old mail-->
                  <div class="form__control">
                    <label>
                      現在のメールアドレス
                      <span class="required"
                            v-text="'必須'"/>
                    </label>

                    <div class="form__control__input">

                    <span class="required"
                          v-text="'必須'"/>

                      <InputField v-model="form.current_email"
                                  class="w-100"
                                  field="現在のメールアドレス"
                                  vid="current_email"
                                  rules="required|email|max:200"/>

                    </div>

                  </div>

                  <!--new mail-->
                  <div class="form__control">
                    <label>
                      新しいメールアドレス
                      <span class="required"
                            v-text="'必須'"/>
                    </label>

                    <div class="form__control__input">

                    <span class="required"
                          v-text="'必須'"/>

                      <InputField v-model="form.new_email"
                                  class="w-100"
                                  field="新しいメールアドレス"
                                  vid="new_email"
                                  rules="required|email|max:200"/>

                    </div>

                  </div>
                </div>

                <!--password-->
                <div v-if="profile.is_sns !== 1"
                     class="group__item">
                  <!--password-->
                  <div class="form__control">
                    <label>
                      パスワード
                      <span class="required"
                            v-text="'必須'"/>
                    </label>

                    <div class="form__control__input">

                    <span class="required"
                          v-text="'必須'"/>

                      <InputField v-model="form.password"
                                  type="password"
                                  rules="required|password|minmax:8,32|password_contain"
                                  class="w-100"
                                  field="パスワード"
                                  vid="password"/>
                    </div>

                  </div>
                </div>

                <!--secret-->
                <div class="group__item">
                  <!--secret question-->
                  <div class="form__control custom-select">
                    <label v-text="'秘密の質問'"/>

                    <div class="form__control__input">
                      <InputSelect v-model.number="form.secret_question"
                                   field="秘密の質問"
                                   vid="secret_question"
                                   :disabled="true"
                                   class="w-50 no-required"
                                   :options="OPTION_SECRET_QUESTION"/>
                    </div>

                  </div>

                  <!--secret answer-->
                  <div class="form__control">
                    <label>
                      秘密の答え
                      <span class="required"
                            v-text="'必須'"/>
                    </label>

                    <div class="form__control__input">

                      <span class="required"
                          v-text="'必須'"/>

                      <InputField v-model="form.secret_answer"
                                  class="w-100"
                                  rules="required|minmax:2,10|only_hiragana"
                                  field="秘密の答え"
                                  vid="secret_answer"/>
                    </div>

                  </div>
                </div>

              </div>

            </div>

            <!--action only pc-->
            <button class="btn__register" type="submit">
              確認する
              <ChevronRightIcon size="24"/>
            </button>

          </ValidationObserver>

        </div>
      </div>

    </div>

    <div v-else class="change-email-confirm" :class="{'padding-has-caution': isHasCaution}">
      <h3 class="title">メールアドレス変更</h3>

      <div class="change-email-confirm__container step">

        <h4>以下のメールアドレスにメールアドレス変更用メールを送信しました。</h4>

        <div class="step__block">

          <!-- ERROR WHEN INPUT TOKEN CODE INCORRECT -->
          <ul v-if="errors_token.length > 0 && checkWebview()"
              class="error__server">
            <li class="errors-mess">{{ errors_token[0] }}</li>
          </ul>

          <div :class="{ 'justify-content-start': checkWebview() }"
               class="mail">
            <img src="@/assets/images/icon/icon-mail.svg" alt="icon">
            {{ form.new_email }}
          </div>

          <!-- FIELD ACCURACY CODE ON MOBILE APP -->
          <ValidationObserver
            v-if="checkWebview()"
            slim
            tag="form"
            ref="observer"
            @submit.prevent="validateBeforeSubmit()">
            <!--code-->
            <div class="form__control mb-3">
              <div class="form__control__input">

                <InputField  v-model="token_code"
                             rules="required|max:6"
                             type="number"
                             class="w-100 code-token"
                             field="認証コード"
                             placeholder="認証コードを入力"
                             vid="token_code">
                  <template #prefix>
                    <img class="prefix" src="@/assets/images/icon/icon-lock.svg" alt="icon">
                  </template>
                </InputField>
              </div>
            </div>
          </ValidationObserver>

          <ul class="note01">
            <li>上記のメールアドレス宛に確認メールを送信しました。</li>
            <li>メール内に記載されたURLにアクセスし、必要事項を入力してください。</li>
          </ul>

          <ul class="note02">
            <li>※ドメイン指定受信をされている場合、「chibijob.com」からのメールを受信できるようご設定ください。</li>
            <li>※お使いのメールソフトによっては、メールが「迷惑メール」フォルダに入る場合がございます。メールが届かない場合は、迷惑フォルダもご確認ください。</li>
          </ul>

        </div>

        <!-- BTN CHECK TOKEN IN MOBILE APP -->
        <button
          v-if="checkWebview()"
          class="btn__register mb-3"
          @click.prevent="validateBeforeSubmit">
          次へ
          <ChevronRightIcon size="24"/>
        </button>

        <a class="btn__register" @click.prevent="openPageNative('Profile.Mypage')">
          マイページへ
          <ChevronRightIcon size="24"/>
        </a>
      </div>
    </div>
     <!-- MODAL CAUTION INFO -->
    <Modal @close-modal="handelRedirectUpdateInfo"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
          <div slot="body">
            <p>メールアドレスを変更する前に、必要な情報を入力してください。</p>

            <!-- button in modal ostiaries_error -->
            <button
                    @click.prevent="handelRedirectUpdateInfo"
                    v-html="'会員情報入力'"
                    class="btn-into-modal"/>
          </div>
    </Modal>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// Component
import InputField from '@/components/form/InputField'
import InputSelect from '@/components/form/InputSelect'
import Modal from '@/components/advertising-detail/Modal'

// Icon
import { ChevronRightIcon } from 'vue-feather-icons'
import { OPTION_SECRET_QUESTION } from '@/enum/options'
import Cookie from 'js-cookie'
import Common from '@/mixins/common.mixin'

export default {
  name: 'ChangMail',

  components: {
    InputField,
    ChevronRightIcon,
    InputSelect,
    Modal
  },

  mixins: [Common],

  data () {
    return {
      form: {
        current_email: '',
        new_email: '',
        password: '',
        secret_question: null,
        secret_answer: ''
      },
      errors: [],
      isConfirm: false,
      token_code: null,
      errors_token: [],
      OPTION_SECRET_QUESTION,
      flagModalRedirect: false,
      isConfirmRedirect: false
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  created () {
    if (this.checkWebview() && !Cookie.get('bearer_token')) {
      this.openPageNative('Login')
    }
    if (this.profile && this.profile.skip_regis_info && !this.isConfirmRedirect) {
      this.flagModalRedirect = true
    }
    this.form.secret_question = this.profile.secret_question
    window.scrollTo(0, 0)
  },

  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  methods: {
    ...mapActions('user', ['userSendChangeEmail', 'userCheckChangeEmail']),

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.isConfirm ? this.handleSubmitToken() : this.handleSubmit()
      } else {
        this.scrollToTop()
      }
    },

    handleSubmit () {
      this.profile.is_sns === 1 && delete this.form.password
      // handle API CHANGE MAIL
      this.userSendChangeEmail(this.form).then(result => {
        if (result.status === 204) {
          this.isConfirm = true
        } else {
          this.handleErrors(result)
        }
      })
    },

    handleSubmitToken () {
      const params = {
        token: this.token_code
      }
      this.userCheckChangeEmail(params).then(result => {
        if (result.code === 422) {
          // Set errors server
          result.errors.forEach(x => {
            this.errors_token.push(x.message[0])
          })
        } else {
          this.triggerEventApp('CHANGE_TOKEN', {
            token: result.data.data.access_token
          })
          this.$router.push({
            path: `/change-email/complete/${result.data.data.access_token}`,

            params: { token: result.data.data.access_token }
          })
        }
      })
    },

    handleErrors (result) {
      this.errors = []

      if (result.status === 400) {
        this.errors.push(result.error.message)
      }

      if (result.code === 422) {
        // Set errors server
        result.errors.forEach(x => {
          this.errors.push(x.message[0])
        })
      }
      // Scroll top
      this.scrollToTop()
    },

    handelRedirectUpdateInfo () {
      this.flagModalRedirect = false
      this.isConfirmRedirect = true
      this.$router.push({ name: 'ChangeProfile' })
    }
  },
  watch: {
    profile: {
      handler (newValue) {
        if (newValue && newValue.skip_regis_info && !this.isConfirmRedirect) {
          this.flagModalRedirect = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-notify {
  @include font-size(18px);
  font-weight: bold;
}
.change-email, .change-email-confirm {
  padding: 90px 0;
  @media #{$info-phone} {
    padding: 85px 0;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 120px;
    }
  }
  &.padding-webview {
    padding: 40px 0;
  }

  .title {
    font-family: $font-family-title;
    color: $default-green;
    @include font-size(36px);
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media #{$info-phone} {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }

  &__container {
    border: 6px solid #E7E5DE;
    border-radius: 6px;
    background-color: #FAFAFA;
    @media #{$info-phone} {
      border: 3px solid #E7E5DE;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }

  .step {
    padding: 65px;
    @media #{$info-phone} {
      padding: 48px 18px;
    }

    h4 {
      text-align: center;
      font-weight: 500;
      line-height: 1.5;
      @include font-size(18px);
      margin-bottom: 45px;
      @media #{$info-phone} {
        margin-bottom: 36px;
        text-align: left;
        font-size: 18px;
      }
    }

    &__form {
      background-color: #E7E5DE;
      border-radius: 6px;
      margin-bottom: 60px;
      @media #{$info-phone} {
        padding: 0;
        margin-bottom: 20px;
      }

      &__group {
        padding: 24px 0;

        @media #{$info-phone} {
          padding: 24px 0;
        }

        .group__item {
          border-bottom: 1px solid #FFFFFF;
          margin-bottom: 24px;
          padding: 0 24px 24px;

          @media #{$info-phone} {
            padding: 0 18px 18px;
            margin-bottom: 10px;
          }

          &:last-child {
            border-bottom: unset;
            margin-bottom: 0;
            padding: 0 24px 0;
            @media #{$info-phone} {
              padding: 0 18px;
            }
          }

          .form__control {
            display: flex;
            align-items: flex-start;
            margin-bottom: 21px;

            &:last-child {
              margin-bottom: 0;
            }

            @media #{$info-phone} {
              display: block;
              margin-bottom: 10px;
            }

            label {
              width: 235px;
              flex-shrink: 0;
              @include font-size(18px);
              margin-top: 13px;
              @media #{$info-phone} {
                width: 100%;
                font-size: 16px;
                display: block;
                margin-bottom: 12px;
                margin-top: 0;
              }

              .required {
                display: none;
                color: #C66C44;
                @include font-size(14px);
                font-weight: bold;
                font-family: $font-family-title;
                margin-top: 16px;
                margin-left: 8px;
                @media #{$info-phone} {
                  display: inline-block;
                }
              }
            }
          }

          .custom-select {
            label {
              margin-top: 6px;
            }

            .form__control__input {
              .required {
                margin-top: 8px;
              }
            }
          }
        }

        .form__control__input {
          display: flex;
          align-items: flex-start;
          flex: 1;
          .no-required {
            margin-left: 52px;
            @media screen and (max-width: 768px) {
              margin-left: 0;
            }
          }
          .required {
            color: #C66C44;
            @include font-size(14px);
            font-weight: bold;
            flex-shrink: 0;
            margin-right: 24px;
            margin-top: 16px;
            @media #{$info-phone} {
              display: none;
            }
          }

          .w-50 {
            @media #{$info-phone}  {
              width: 100%;
            }
          }
        }

      }
    }

    .btn__register {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 360px;
      width: 100%;
      border-radius: 6px;
      color: #ffffff;
      background: #6F8784;
      font-family: $font-family-title;
      @include font-size(18px);
      padding: 14px;
      margin: auto;
      cursor: pointer;

      @media #{$info-phone} {
        font-size: 16px;
        padding: 10px;
        max-width: 100%;
      }

      svg {
        margin-left: 5px;
      }
    }

  }
}

.change-email-confirm {
  .step {
    &__block {
      background-color: #E7E5DE;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 22px;
      padding: 36px 108px;
      margin-bottom: 47px;

      @media #{$info-tablet-vertical} {
        padding: 36px 70px;
      }

      @media #{$info-phone} {
        padding: 24px 18px;
        margin-bottom: 20px;
      }

      .mail {
        background-color: #ffffff;
        text-align: center;
        border-radius: 6px;
        margin-bottom: 25px;
        font-family: $font-family-title;
        color: $default-color;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        word-break: break-all;
        img {
          margin-top: 2px;
          margin-right: 5px;
        }
        @media #{$info-phone} {
          font-size: 16px;
          padding: 10px 15px;
          text-align: left;
          img {
            margin-right: 10px;
            width: 20px;
          }
        }
      }

      ul {
        li {
          line-height: 28px;
          font-weight: 500;
          @include font-size(16px);
          @media #{$info-phone} {
            font-size: 16px;
          }
          &.errors-mess {
            font-size: unset;
            font-weight: 600;
          }
        }
      }

      .note01 {
        border-bottom: 1px solid #ffffff;
        padding-bottom: 28px;
        margin-bottom: 28px;
        @media #{$info-phone} {
          padding-bottom: 24px;
          margin-bottom: 24px;
        }
      }

      .note02 {
        li {
          text-indent: -10px;
          margin-left: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .btn__register {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    background: #6F8784;
    font-family: $font-family-title;
    @include font-size(18px);
    padding: 14px;
    margin: auto;
    cursor: pointer;

    @media #{$info-phone} {
      font-size: 16px;
      padding: 10px;
      max-width: 100%;
    }

    svg {
      margin-left: 5px;
    }
  }

}

@media #{$info-phone} {
  .container {
    padding: 0;
  }
}
</style>
